import React, { useEffect, useState } from "react";
import cx from "classnames";
import ScrollLock from "react-scrolllock";

import User from "./component/User";
import Links from "./component/Links";

import logo from "../../assets/images/logo-new-black.png";
// import logoBlack from "../../assets/images/logo-black.svg";
// import logoWhite from "../../assets/images/logo-white.svg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import LoginAsBanner from "../LoginAsBanner";
import { useAppSelector } from "../../store/hooks";

type Props = {
  // TODO: check correct type while describe types
  children: React.ReactNode;
  wide?: boolean;
  medium?: boolean;
  center?: boolean;
  hideNavigation?: boolean;
  background?: string;
  className?: string;
  isHeaderAnimated?: boolean;
  hide?: boolean;
  close?: boolean;
  headerTop?: unknown;
  noScroll?: unknown;
  fluid?: unknown;
  lessPadding?: boolean;
  full?: boolean;
  // withoutContainer?: boolean;
  childrenOutContainer?: React.ReactNode;
  noMorePadding?: boolean;
  headerAbsolute?: boolean;
  withoutSpacing?: boolean;
  fluidFull?: boolean;
};

type State = {
  isMenuOpen: boolean;
};

const Layout = (props: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const { noMorePadding = false, headerAbsolute = false } = props;
  useEffect(() => {
    window.addEventListener("resize", handleScreenWidthChange);
    return window.removeEventListener("resize", handleScreenWidthChange);
  });

  const handleScreenWidthChange = () => setWindowWidth(window.innerWidth);

  const toggle = () => setIsMenuOpen(!isMenuOpen);

  const headerClassNames = classNames({
    header: true,
    "go-to-back": !props.headerTop,
    "menu-active": isMenuOpen,
    "header__slide--out": props.isHeaderAnimated && props.hideNavigation,
    "header__slide--in": props.isHeaderAnimated && !props.hideNavigation,
    "header--hidden": props.isHeaderAnimated,
    "header--close": props.close,
  });

  const isLoginAs = useAppSelector((state) => !!state.user?.isLoginAs);

  return (
    <div>
      <LoginAsBanner />
      <div
        className="page"
        style={
          props.background && {
            backgroundImage: `url(${props.background})`,
          }
        }
      >
        <ScrollLock isActive={!!props.noScroll && windowWidth > 768} />
        <div className={headerClassNames}>
          <div className="header-content">
            <Link to="/" className="logo">
              <img className="logo-default" src={logo} alt="logo" />
              <img className="logo-black" src={logo} alt="logo" />
              <img className="logo-mobile" src={logo} alt="logo" />
            </Link>
            <div className="header--left">
              <Links />
              <User toggle={toggle} isOpen={isMenuOpen} />
            </div>
          </div>
        </div>
        {props.childrenOutContainer}
        <div
          className={cx("container", props.className, {
            medium: props.medium,
            wide: props.wide,
            center: props.center,
            fluid: props.fluid,
            full: props.full,
            'fluid-full': props.fluidFull,
            lessPadding: props.lessPadding,
            morePadding: isLoginAs && !noMorePadding,
            "header--absolute": headerAbsolute,
            "wo-spacing": props.withoutSpacing
          })}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  headerTop: state.app.headerTop,
});

export default connect(mapStateToProps, {})(Layout);
