import { call, put, takeLatest } from "redux-saga/effects";
import jwt from "jsonwebtoken";

import { actions as SessionActions } from "../../store/session/reducer";
import { actions as UserActions } from "../../store/user/reducer";
import {
  callRequestReset,
  callSignIn,
  callSignUp,
  callResetPassword
} from "../../api/authentication";
import {
  callGetSubscriptions,
  callCreatePortalSession
} from "../../api/subscriptions";
import {
  googleAnalitics,
  CATEGORY_TYPES,
  ACTION_TYPES
} from "../../utils/GoogleAnalitics";
import { callGetProfile } from "../../api/user";

function* authenticateUser(user) {
  const tokenPayload = jwt.decode(user.token, { complete: true }).payload;
  yield put(
    UserActions.setUser({
      id: user._id,
      email: user.email,
      name: `${user.firstName} ${user.lastName}`.trim(),
      image: user.profileImage,
      createdAt: user.createdAt,
      country: user.country,
      countryCode: user.countryCode,
      firstName: user.firstName,
      lastName: user.lastName,
      isLoginAs: !!user.isLoginAs,
      subscription: user.subscription,
      subscriptionStatus: user.subscriptionStatus
    })
  );
  yield put(
    SessionActions.setToken({
      accessToken: user.token,
      expiration: tokenPayload.exp * 1000
    })
  );
}

export function* login(action) {
  try {
    yield put({ type: "LOGIN_REQUEST" });
    const response = yield call(callSignIn, action.email, action.password);

    yield authenticateUser(response.data);
    yield put({ type: "LOGIN_SUCCESS" });

    if (response.data.subscription.status === "past-due") {
      // @ts-ignore
      const res = yield call(callCreatePortalSession);
      window.open(res.data.url, "_self");
    } else if (response.data.subscription.status === "past-due") {
      window.location.href = "/account/subscription";
    }
  } catch (error) {
    yield put({
      type: "LOGIN_FAILURE",
      error: error.code === 401 ? "Invalid username or password" : error.message
    });
  }
}

export function* register(action) {
  try {
    yield put({ type: "SIGN_UP_REQUEST" });
    const response = yield call(callSignUp, action.body);

    const subscriptionsResponse = yield call(callGetSubscriptions);
    const { subscriptions } = subscriptionsResponse.data;

    const monthly = subscriptions.find(s => s.period === "month");

    yield authenticateUser(response.data);

    if (response.data?.abTesting) {
      yield put({ type: "SIGN_UP_SUCCESS" });
      googleAnalitics.logEvent({
        category: CATEGORY_TYPES.subscription,
        action: ACTION_TYPES.experimentalGroupMember
      });
    }
    googleAnalitics.logEvent({
      category: CATEGORY_TYPES.subscription,
      action: ACTION_TYPES.register
    });

    // if (!response.data?.abTesting) {
    action.navigateCheckout();
    // }
    // if (monthly.checkoutLink && !action.body.isMaintenancePlan) {
    //   window.open(monthly.checkoutLink, "_self")
    // } else {
    //   action.navigateCheckout(monthly._id);
    // }
  } catch (error) {
    action.onError(
      error.code === 500 ?
        'Unexpected error occurred. Please, try again'
        : error.message
    )
    yield put({
      type: "SIGN_UP_FAILURE",
      error: error.message
    });
  }
}

export function* requestReset(action) {
  try {
    yield put({ type: "POST_RESET_REQUEST" });
    const response = yield call(callRequestReset, action.email);

    action.onSuccess();

    yield put({ type: "POST_RESET_SUCCESS" });
  } catch (error) {
    console.log(error);
    yield put({
      type: "POST_RESET_FAILURE",
      error: error.message
    });
  }
}

export function* resetPassword(action) {
  try {
    yield put({ type: "RESET_PASSWORD_REQUEST" });
    const response = yield call(
      callResetPassword,
      action.token,
      action.password
    );

    action.onSuccess();

    yield put({ type: "RESET_PASSWORD_SUCCESS" });
  } catch (error) {
    yield put({
      type: "RESET_PASSWORD_FAILURE",
      error: error.message
    });
  }
}

export function* logout() {
  yield put(UserActions.clearUser());
  yield put(SessionActions.clearToken());
}

export function* loginAs(action) {
  yield logout();
  yield put(
    SessionActions.setToken({
      accessToken: action.loginAsToken,
      expiration: null
    })
  );
  const response = yield call(callGetProfile);
  yield authenticateUser({
    ...response.data,
    token: action.loginAsToken,
    isLoginAs: true
  });
}

export function* getProfileSubscription(action) {
  try {
    yield put({ type: "GET_PROFILE_REQUEST" });
    const response = yield call(callGetProfile);

    yield put(UserActions.setSubscription(response.data.subscriptionStatus))


    yield put({ type: "GET_PROFILE_SUCCESS" });
  } catch (error) {
    yield put({
      type: "GET_PROFILE_FAILURE",
      error: error.message
    });
  }
}

const authSaga = [
  takeLatest("LOGIN", login),
  takeLatest("SIGN_UP", register),
  takeLatest("LOGOUT", logout),
  takeLatest("REQUEST_RESET", requestReset),
  takeLatest("RESET_PASSWORD", resetPassword),
  takeLatest("LOGIN_AS", loginAs),
  takeLatest('GET_PROFILE', getProfileSubscription)
];

export default authSaga;
