import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router";

import {
  defaultRoutesList,
  memberRoutesList,
  guestRoutesList,
} from "./routesLists";
import { useShowHelper } from "utils/customHooks/use-show-helper";

const AuthContext = React.createContext(false);

const AuthRoute = ({ component: Component, ...rest }) => {
  useShowHelper(rest.isShowHelper);
  return (
    <Route
      {...rest}
      render={(props) => (
        <AuthContext.Consumer>
          {(isAuthenticated) =>
            isAuthenticated ? (
              <Component {...props} />
            ) : (
              <Redirect to={{ pathname: "/login" }} />
            )
          }
        </AuthContext.Consumer>
      )}
    />
  );
};

const UnauthRoute = ({ component: Component, ...rest }) => {
  useShowHelper(rest.isShowHelper);
  return (
    <Route
      {...rest}
      render={(props) => (
        <AuthContext.Consumer>
          {(isAuthenticated) =>
            !isAuthenticated ? (
              <Component {...props} />
            ) : (
              <Redirect to="/home" />
            )
          }
        </AuthContext.Consumer>
      )}
    />
  );
};

type Props = {
  isAuthenticated: boolean;
  checkSubscriptions: (func) => void;
  trialEnded: boolean;
};

const Routes = (props: Props) => {
  const [subscriptionsChecked, setSubscriptionsChecked] = useState(false);

  useEffect(() => {
    if (props.isAuthenticated) {
      props.checkSubscriptions(() => setSubscriptionsChecked(true));
    } else {
      setSubscriptionsChecked(false);
    }
  }, [props.isAuthenticated]);

  const guestRoutes = (list) => {
    return [
      list.authRoutes.map((guestRoute, i) => (
        <AuthRoute
          exact
          key={`guest-${i}`}
          path={`${guestRoute.path}`}
          component={guestRoute.component}
        />
      )),
      <Redirect key="redirect-guest-route" to="/account/subscription" />,
    ];
  };

  const memberRoutes = (list) => {
    return [
      list.authRoutes.map((memberRoute, i) => (
        <AuthRoute
          exact
          key={`member-${i}`}
          path={`${memberRoute.path}`}
          component={memberRoute.component}
          isShowHelper={memberRoute.isShowHelper}
        />
      )),

      <Redirect key="redirect-member-route" to="/home" />,
    ];
  };

  if (!subscriptionsChecked && props.isAuthenticated) {
    return null;
  }

  return (
    <AuthContext.Provider value={props.isAuthenticated}>
      <Switch>
        {defaultRoutesList.unauthRoutes.map((route, i) => (
          <UnauthRoute
            isShowHelper={route.isShowHelper}
            exact
            key={`default-unauth-${i}`}
            path={route.path}
            component={route.component}
          />
        ))}
        {defaultRoutesList.authRoutes.map((route, i) => (
          <AuthRoute
            isShowHelper={route.isShowHelper}
            exact
            key={`default-auth-${i}`}
            path={route.path}
            component={route.component}
          />
        ))}

        {props.trialEnded
          ? guestRoutes(guestRoutesList)
          : memberRoutes(memberRoutesList)}
      </Switch>
    </AuthContext.Provider>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated:
      !!state.session.accessToken &&
      state.session.expiration > Date.now() &&
      !state.loading["LOGIN"] &&
      !state.loading["SIGN_UP"],
    trialEnded:
      !state.loading["GET_USER_SUBSCRIPTIONS"] &&
      (!state.subscriptions.user ||
        (!state.subscriptions.user._id &&
          state.subscriptions.user.name !== "Legacy Membership") ||
        state.subscriptions.user.status === "past-due"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  checkSubscriptions: (onSuccess) =>
    dispatch({ type: "GET_USER_SUBSCRIPTIONS", onSuccess }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
