import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    headerTop: true,
    headerLinksHidden: false,
  };

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        headerToBottom: (state, _action) => {
            state.headerTop = false
        },
        headerOnTop: (state, _action) => {
            state.headerTop = true
        },
        toggleHeaderLinks: (state, action) => {
            state.headerLinksHidden = action.payload
        }
    }
})

export const { actions } = appSlice;

export default appSlice.reducer;
