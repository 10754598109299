import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";

import Layout from "../../components/Layout";
import Quote from "./components/Quote";
import PlanCard from "./components/PlanCard";
import { actions as PlansActions } from "../../store/plans/reducer";
import { callGetQuote } from "../../api/misc";
import { callGetAdminPlans, callGetLastModifiedPlan } from "../../api/plans";

import DeletePlanModal from "../../components/DeletePlanModal";
import star from "../../assets/images/common/aacp_star.svg";
import SavePlan from "../../components/SavePlanModal";

import { RecipeList } from "./RecipeList";
import { useGetLabelForCustomWeekPlan } from "utils/customHooks/use-get-label-for-custom-week";
import { useAppSelector } from "store/hooks";
import { useGetCurrentWeek } from "../../utils/customHooks/use-get-current-week";
import useEmblaCarousel from "embla-carousel-react";

import { IImages } from "api/recipes/type";
import { SliderNavigation } from "components/SliderNavigation";
import SavePlanModal from "../../components/SavePlanModal";
import { IRecipe } from "./components/RecipeCard";
import { callGetRecipes, callGetTopRecipesFilter } from "api/recipes";
import { useMediaQuery } from "utils/customHooks/use-media-query";
import { PlanList } from "./components/PlanList";
import AACPModal from "components/AACPModal";

type Props = {
  getPlans: (start: string, end: string) => void;
  updateCustomPlan: (id, meals, name, onSuccess) => void;
  savePlan: (body, onSuccess) => void;
  deleteCustomPlan: (id, onSuccess) => void;
  history: any;
  plans: any[];
  removeCurrentPlans: () => void;
  removeCreatedPlanId: () => void;
  allAACP: any[];
  isAACPActive: boolean;
};

const MealPlans = (props: Props) => {
  const [quote, setQuote] = useState({ body: null, author: null });
  const mobileView = useMediaQuery("screen and (max-width: 475px)");
  const [weeklyPLans, setWeeklyPlans] = useState([]);
  const [renameModalVisible, setRenameModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [plan, setPlan] = useState(null);
  const [lastModifiedPlans, setLastModifiedPlans] = useState<any>();
  const [plans, setPlans] = useState([]);
  const [updatePlans, setUpdatePlans] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const recipeTypes = useAppSelector(state => state.enum.types);
  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const [favouriteRecipes, setFavouriteRecepies] = useState<IRecipe[]>([]);
  const [aacpModalOpen, setAacpModalOpen] = useState(false);
  const [topRecipes, setTopReceipes] = useState<{
    meal: IRecipe[];
    smoothie: IRecipe[];
  }>({ meal: [], smoothie: [] });
  useEffect(() => {
    const fetchData = async () => {
      props.removeCurrentPlans();
      props.removeCreatedPlanId();
      const quoteResponse = await callGetQuote();
      setQuote(quoteResponse.data);

      const latestPlansResponse = await callGetLastModifiedPlan(4, 1);
      setLastModifiedPlans(latestPlansResponse.data.plans);
    };
    fetchData();
    dispatch({ type: "GET_ENUMS", enums: ["types"] });
    dispatch({ type: "GET_AACP_PLANS" });
  }, []);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    draggable: true,
    dragFree: true,
    align: "start",
    breakpoints: {
      "(min-width: 768px)": { active: false },
      "(max-width: 768px)": { active: true }
    }
  });

  useEffect(() => {
    (async () => {
      const response = await callGetRecipes(1, {
        favorite: true,
        takeLattest: 8
      });
      setFavouriteRecepies(response.data.stats.results);
    })();
  }, []);

  useEffect(() => {
    emblaApi?.reInit();
  }, [emblaApi, lastModifiedPlans]);

  const getRecipeTypeId = useCallback(
    (type: string) => {
      if (recipeTypes.length > 0) {
        return recipeTypes?.find(el => el.name === type)._id;
      }
    },
    [recipeTypes]
  );

  useEffect(() => {
    if (props.plans) {
      deleteModalVisible && setDeleteModalVisible(false);
      renameModalVisible && setRenameModalVisible(false);
      setPlans(props.plans.slice());
    }
  }, [props.plans]);

  useEffect(() => {
    if (!updatePlans) return;
    (async () => {
      const latestPlansResponse = await callGetLastModifiedPlan(4, 1);
      setLastModifiedPlans(latestPlansResponse.data.plans);
      setUpdatePlans(false);
    })();
  }, [updatePlans]);

  const getLabelForCustomWeekPlan = useGetLabelForCustomWeekPlan();

  const goPlanPage = (id: string, daysSubstract?: number) => {
    history.push({
      pathname: `/plans/${id}`,
      state: {
        // WARN: HERE IS ALWAYS UNDEFINED???
        daysSubstract
      }
    });
  };

  const openDeleteModal = plan => {
    setDeleteModalVisible(true);
    setPlan(plan);
  };

  const deletePlan = () => {
    props.deleteCustomPlan(plan.nameSlug, () => {
      setUpdatePlans(true);
      closeDeleteModal();
    });
  };

  const closeDeleteModal = () => {
    setDeleteModalVisible(false);
    setPlan(null);
  };

  const openRenameModal = plan => {
    setRenameModalVisible(true);
    setPlan(plan);
  };

  const renamePlan = async name => {
    const mealPlans = [...plan.mealPlans].map(plan => ({
      ...plan,
      _id: undefined,
      recipe: plan.recipe._id
    }));
    props.updateCustomPlan(plan.nameSlug, mealPlans, name, () => {
      setUpdatePlans(true);
      closeRenameModal();
    });
  };

  const closeRenameModal = () => {
    setRenameModalVisible(false);
    setPlan(null);
  };

  React.useEffect(() => {
    if (recipeTypes.length > 0) {
      (async () => {
        const response = await callGetTopRecipesFilter({
          page: 1,
          limit: 8,
          types: [getRecipeTypeId("Meal"), getRecipeTypeId("Smoothie")],
          minRating: 4,
          excludeAllergens: true,
          sortBy: "avgRating"
        });
        setTopReceipes({
          meal: response.data.recipes.meals,
          smoothie: response.data.recipes.smoothies
        });
      })();
    }
  }, [recipeTypes]);

  const { start: startWeek } = useGetCurrentWeek();
  const getWeekPlanNameSlug = startTime => {
    if (startTime) {
      const start = moment.utc(startTime);
      return `${start.format("MMM-DD-YY")}`;
    }

    return startWeek;
  };

  return (
    <div className="showHelper">
      <Layout
        fluid
        full
        // center
        lessPadding
        childrenOutContainer={<Quote text={quote.body} name={quote.author} />}
      >
        {/* <div className="flex-row"></div> */}
        <div className="container pt-70 wide lessPadding embla bottom-padding">
          <h2 className="recipe-h2">Latest Plans</h2>
          <div ref={emblaRef} className="embla__viewport_default">
            <div className="plans-grid plans--week grid-4 plans--week--fixheight embla__container_default">
              {lastModifiedPlans?.map((plan, index) => (
                <PlanCard
                  label={plan?.name}
                  key={index}
                  custom={false}
                  openDeleteModal={() => openDeleteModal(plan)}
                  openRenameModal={() => openRenameModal(plan)}
                  placeholder={getLabelForCustomWeekPlan(plan.start, plan.end)}
                  onClick={() =>
                    goPlanPage(plan.nameSlug || getWeekPlanNameSlug(plan.start))
                  }
                  images={plan.photo?.recipe?.images as IImages}
                  className="embla__slide"
                />
              ))}
            </div>
            {mobileView && (
              <SliderNavigation
                classNames={{ root: "top-recipe-navigation" }}
                onLeftClick={() => emblaApi?.scrollPrev()}
                onRightClick={() => emblaApi?.scrollNext()}
              />
            )}
          </div>
        </div>

        <SavePlanModal
          isCustomPlan={plan && plan.name}
          isCreatePlan={false}
          visible={renameModalVisible}
          onSave={renamePlan}
          close={closeRenameModal}
          currentName={plan?.name || ""}
          confirmRequired={true}
          closeSuccess={setSuccessModalVisible}
          visibleSuccess={successModalVisible}
        />
        <DeletePlanModal
          visible={deleteModalVisible}
          onDelete={deletePlan}
          close={closeDeleteModal}
          confirmRequired={true}
        />
        {recipeTypes.length > 0 && (
          <RecipeList
            title="Top-Rated Meals"
            bgGray
            recipes={topRecipes.meal}
            minRating={4.5}
            page={1}
            sortBy="avgRating"
            sortAt={-1}
            excludeAllergens
            slidesToScroll={mobileView ? 1 : 4}
            limit={8}
            types={[getRecipeTypeId("Meal")]}
            additionalSortBy="lastReviewDate"
            fullRecipes={true}
            additionalSortAt={-1}
            imageAsBg={!mobileView}
            classNames={{
              grid: "grid-4 g-gap18",
              root: "pt-70",
              card: {
                wrapper: "wrap card-25",
                title: "mobile",
                content: "mobile",
                image: "mobile",
                rating: "mobile"
              }
            }}
            disableSlider={false}
            gridSlider={mobileView}
          />
        )}
        {!!props.allAACP.length && (
          <PlanList
            title="Cleanse Plans"
            plans={props.allAACP}
            disabled={!props.isAACPActive}
            onAACPClick={() => setAacpModalOpen(true)}
            isAACP={true}
            titleIcon={<img src={star} className="title-icon" />}
          />
        )}
        {recipeTypes.length > 0 && (
          <RecipeList
            classNames={{
              root: "bottom-padding pt-70",
              grid: "recipes-big-grid plans--week--fixheight g-gap25",
              card: {
                wrapper: "card-50",
                content: "recipe-content-big"
              }
            }}
            limit={8}
            page={1}
            title="Popular Smoothies"
            recipes={topRecipes.smoothie}
            excludeAllergens
            types={[getRecipeTypeId("Smoothie")]}
            sortBy="cooking"
            bgGray={!!props.allAACP.length}
            sortAt={-1}
            slidesToScroll={mobileView ? 1 : 2}
            additionalSortBy="lastReviewDate"
            additionalSortAt={-1}
            disableSlider={false}
            minRating={4}
          />
        )}
        {favouriteRecipes.length > 0 && (
          <RecipeList
            title="YOUR FAVORITES"
            bgGray={!props.allAACP.length}
            minRating={4.5}
            page={1}
            sortBy="avgRating"
            sortAt={-1}
            excludeAllergens
            limit={8}
            types={[getRecipeTypeId("Meal")]}
            additionalSortBy="lastReviewDate"
            fullRecipes={true}
            slidesToScroll={mobileView ? 1 : 4}
            additionalSortAt={-1}
            imageAsBg={!mobileView}
            classNames={{
              grid: "grid-4 g-gap18",
              root: "pt-70 pb-30",
              card: {
                wrapper: "wrap card-25",
                title: "mobile",
                content: "mobile",
                image: "mobile",
                rating: "mobile"
              }
            }}
            disableSlider={false}
            recipes={favouriteRecipes}
            gridSlider={mobileView}
          />
        )}
        <AACPModal
          visible={aacpModalOpen}
          onClose={() => setAacpModalOpen(false)}
        />
      </Layout>
    </div>
  );
};

const mapStateToProps = state => ({
  plans: state.plans.all,
  allAACP: state.plans.allAACP,
  isAACPActive: state.subscriptions.user.isAACPActive
});

const mapDispatchToProps = dispatch => ({
  getPlans: (start: number, end: number) =>
    dispatch({ type: "GET_PLANS", start, end }),
  updateCustomPlan: (id, meals, name, onSuccess) =>
    dispatch({ type: "UPDATE_CUSTOM_PLAN", id, meals, name, onSuccess }),
  savePlan: (body, onSuccess) =>
    dispatch({ type: "SAVE_PLAN", body, onSuccess }),
  deleteCustomPlan: (id, onSuccess) =>
    dispatch({ type: "DELETE_PLAN", id, onSuccess }),
  removeCurrentPlans: () => dispatch(PlansActions.setPlan({ data: [] })),
  removeCreatedPlanId: () => dispatch(PlansActions.setCreatedPlanId({ id: "" }))
});

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(MealPlans);
