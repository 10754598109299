// @flow
import React from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

import close from "../../../../assets/images/common/close.svg";
import closeBlack from "../../../../assets/images/common/close-black.svg";
import star from "../../../../assets/images/common/aacp_star.svg";
import open from "../../../../assets/images/common/menu-burger.svg";
import avatar from "../../../../assets/images/user.svg";
import {
  googleAnalitics,
  CATEGORY_TYPES,
  ACTION_TYPES
} from "../../../../utils/GoogleAnalitics";
import { callCreatePortalSession } from "../../../../api/subscriptions";
import bullets from "../../../../assets/images/common/ellipse-vert.svg";

const logShopLinkClickEvent = () => {
  googleAnalitics.logEvent({
    category: CATEGORY_TYPES.ecommerce,
    action: ACTION_TYPES.openShop
  });
};

type Props = {
  isOpen: boolean;
  toggle: () => void;
  logout: () => void;
};

const User = (props: Props) => (
  <>
    <Desktop {...props} />
    <Tablet {...props} />
    <Mobile {...props} />
  </>
);

const SubscriptionLink = ({ trialEnded, paused }) => {
  const history = useHistory();

  const handleClick = async () => {
    if (trialEnded && !paused) {
      history.push("/account/subscription");
    } else {
      try {
        // @ts-ignore
        const res = await callCreatePortalSession();
        window.open(res.data.url, "_self");
      } catch (err) {
        console.log("[Subscription.Error]", err);
      }
    }
  };

  return (
    <div className="subscription-link" onClick={handleClick}>
      Subscription
    </div>
  );
};

const Desktop = props => (
  <div className={`user ${props.isOpen ? "open" : ""}`}>
    <div className="user-block" onClick={props.toggle}>
      <img className="avatar" src={props.user.image || avatar} alt="user" />
      <div className="user-info">
        <div className="name-wrapper">
          <p className="name">
            {props.user.firstName
              ? `${props.user.firstName} ${props.user.lastName}`
              : props.user.name}
          </p>
          {props.isAACPActive && (
            <div>
              <img className="icon" src={star} title="All access clean pass" />
            </div>
          )}
        </div>
        <p>Rawkstar since {moment(props.user.createdAt).format("YYYY")}</p>
      </div>
      <img className="arrow" src={bullets} alt="arrow" />
    </div>
    <div className="user-menu">
      <div className="user-links">
        <Link to="/account/profile">Profile</Link>
        <SubscriptionLink trialEnded={props.trialEnded} paused={props.paused} />
        <a href="https://collective.rawktheyear.com/c/meal-planner" target="_blank">
          FAQS + DEMO
        </a>
        {/* <Link to="/account/refer-a-friend">Refer a friend</Link> */}
        <a
          href="https://simplegreensmoothies.com/shop"
          target="_blank"
          rel="noreferrer noopener"
          onClick={logShopLinkClickEvent}
        >
          Rawkstar Shop
        </a>
        <a href="Log out" rel="noreferrer noopener" onClick={props.logout}>
          sign out
        </a>
      </div>
    </div>
  </div>
);

const Tablet = props => (
  <div className={`user user-tablet ${props.isOpen ? "open" : ""}`}>
    <div className="toggle-button" onClick={props.toggle}>
      <img
        className="svg-button"
        src={props.isOpen ? close : open}
        alt="Menu"
      />
      {/*CONFLICT <img className="svg-button" src={props.isOpen ? closeBlack : open} alt="Menu" /> */}
    </div>
    <div className="user-menu">
      <div className="user-block">
        <img className="avatar" src={props.user.image || avatar} alt="user" />
        <div className="user-info">
          <div className="name-wrapper">
            <p className="name">
              {props.user.firstName
                ? `${props.user.firstName} ${props.user.lastName}`
                : props.user.name}
            </p>
            {props.isAACPActive && (
              <div>
                <img
                  className="icon"
                  src={star}
                  title="All access clean pass"
                />
              </div>
            )}
          </div>
          <p>Rawkstar since {moment(props.user.createdAt).format("YYYY")}</p>
        </div>
      </div>
      <div className="user-links">
        <Link to="/account/profile">Profile</Link>
        <SubscriptionLink trialEnded={props.trialEnded} paused={props.paused} />
        <a href="https://collective.rawktheyear.com/c/meal-planner" target="_blank">
          FAQS + DEMO
        </a>
        {/* <Link to="/account/refer-a-friend">Refer a friend</Link> */}
        <a
          href="https://simplegreensmoothies.com/shop"
          target="_blank"
          rel="noreferrer noopener"
          onClick={logShopLinkClickEvent}
        >
          Shop
        </a>
        <a href="Log out" rel="noreferrer noopener" onClick={props.logout}>
          sign Out
        </a>
      </div>
    </div>
  </div>
);

const Mobile = props => (
  <div className={`user user-mobile ${props.isOpen ? "open" : ""}`}>
    <div className="toggle-button" onClick={props.toggle}>
      <img
        className="svg-button"
        src={props.isOpen ? closeBlack : open}
        alt="Menu"
      />
    </div>
    <div className="user-menu">
      <div className="user-block">
        <img className="avatar" src={props.user.image || avatar} alt="user" />
        <div className="user-info">
          <div className="name-wrapper">
            <p className="name">
              {props.user.firstName
                ? `${props.user.firstName} ${props.user.lastName}`
                : props.user.name}
            </p>
            {props.isAACPActive && (
              <div>
                <img
                  className="icon"
                  src={star}
                  title="All access clean pass"
                />
              </div>
            )}
          </div>
          <p>Rawkstar since {moment(props.user.createdAt).format("YYYY")}</p>
        </div>
      </div>
      <div className="user-links">
        <Link to="/account/profile">Profile</Link>
        <SubscriptionLink trialEnded={props.trialEnded} paused={props.paused} />
        <a href="https://collective.rawktheyear.com/c/meal-planner" target="_blank">
          FAQS + DEMO
        </a>
        {/* <Link to="/account/refer-a-friend">Refer a friend</Link> */}
        <a
          href="https://simplegreensmoothies.com/shop"
          target="_blank"
          rel="noreferrer noopener"
          onClick={logShopLinkClickEvent}
        >
          Shop
        </a>
        <a href="Log out" rel="noreferrer noopener" onClick={props.logout}>
          Sign out
        </a>
      </div>
    </div>
  </div>
);

const mapStateToProps = state => ({
  user: state.user,
  trialEnded:
    !state.loading["GET_USER_SUBSCRIPTIONS"] &&
    (!state.subscriptions.user ||
      (!state.subscriptions.user._id &&
        state.subscriptions.user.name !== "Legacy Membership") ||
      state.subscriptions.user.status === "past-due"),
  paused:
    !state.loading["GET_USER_SUBSCRIPTIONS"] &&
    state.subscriptions.user &&
    state.subscriptions.user.status === "past-due",
  isAACPActive:
    state.subscriptions.user && state.subscriptions.user.isAACPActive
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch({ type: "LOGOUT" })
});

export default connect(mapStateToProps, mapDispatchToProps)(User);
