import React, { useState, useEffect } from "react";

import PageHeader from "../../../components/PageHeader";
import Button from "../../../components/Button";
import Input from "../../Input";
import { MultileSelect, Select } from "../../InputWithCheckboxes";

import { call } from "../../../api/core";
import user from "../../../assets/images/user.svg";
import check from "../../../assets/images/common/check-w.svg";
import { getChangedFields, isValidEmail } from "../../../utils";
import { withRouter } from "react-router-dom";
// import CountrySelect from "../../CountrySelect";

type Props = {
  updateUser: (data: any, onSuccess: () => void) => void;
  error?: string;
  loading: boolean;
  allergens: { value: string; label: string }[];
  units: { value: string; label: string }[];
  match: {
    params: {
      section: unknown;
    };
  };
};

type Errors = null | {
  email?: string;
  oldPassword?: string;
  newPassword?: string;
};

type State = {
  firstName: string;
  lastName: string;
  email: string;
  location: string;
  oldPassword: string;
  newPassword: string;
  allergies: string[];
  image: string | null;
  profileImage: string | null;
  changedFields: string[];
  errors: Errors;
  success: boolean;
  loadingImage: boolean;
};

const ProfileTab = (props: Props) => {
  const [state, setCurrentState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    // country: '',
    // location: '',
    oldPassword: "",
    newPassword: "",
    allergies: [],
    image: null,
    profileImage: null,
    changedFields: [],
    errors: null,
    success: false,
    loadingImage: false
  });

  const setState = (newState: Partial<State>, cb?) => {
    setCurrentState(prevState => ({ ...prevState, ...newState }));
    if (cb) return cb();
  };

  useEffect(() => {
    const fetchData = async () => {
      if (props.match.params.section === "password") {
        document.getElementById("password").scrollIntoView();
      }

      const response = await call("/profile", "GET", {});

      setState({
        ...response.data,
        allergies: response.data.allergies.map(a => a._id)
      });
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (props.match.params.section === "password") {
      document.getElementById("password").scrollIntoView();
    }
  }, [props.match.params.section]);

  useEffect(() => {
    if (state.profileImage === "") {
      handleUpdateUser();
    }
  }, [state.profileImage]);
  const keepChangedField = (field: string | string[]) => {
    if (!state.changedFields.includes(field)) {
      setState({
        changedFields:
          typeof field === "string"
            ? [...state.changedFields, field]
            : [...state.changedFields, ...field]
      });
    }
  };

  const updateField = (field: string, value: string) => {
    setState({
      [field]: value,
      errors: null,
      success: false
    });

    if (field === "oldPassword" || field === "newPassword") {
      keepChangedField(["oldPassword", "newPassword"]);
    } else {
      keepChangedField(field);
    }
  };

  const updateMultipleField = (field: string, value: any) => {
    const copy = [...state[field]];
    const index = copy.indexOf(value);

    if (index === -1) {
      copy.push(value);
    } else {
      copy.splice(index, 1);
    }

    setState({
      [field]: copy,
      errors: null,
      success: false
    });

    keepChangedField(field);
  };

  const handleUpload = async e => {
    setPhotoUploadError("");
    setState({ loadingImage: true, errors: null, success: false });
    if (!e.currentTarget.files[0]) {
      return;
    }

    const formData = new FormData();
    formData.append("file", e.target.files[0]);

    const oldImage = state.image;
    const oldProfileImage = state.profileImage;

    setState({
      image: URL.createObjectURL(e.target.files[0])
    });

    try {
      const response = await call("/files/upload", "POST", {
        headers: { "Content-Type": "multipart/form-data" },
        body: formData
      });

      setState({
        profileImage: response.data.tmpFile
      });

      keepChangedField("profileImage");
    } catch (err) {
      setPhotoUploadError(
        "Failed to upload an image. Make sure that the file size does not exceed 5 MB."
      );
      setState({
        profileImage: oldProfileImage,
        image: oldImage
      });
    } finally {
      setState({ loadingImage: false });
    }
  };

  const deletePhoto = () => {
    setState({
      image: null,
      profileImage: "",
      changedFields: [...state.changedFields, "image", "profileImage"]
    });
    setPhotoUploadError("");
  };

  const validate = () => {
    let hasErrors = false;
    const errors: Errors = {};

    if (state.changedFields.includes("email") && !isValidEmail(state.email)) {
      hasErrors = true;
      errors.email = "Email is invalid";
    }

    if (state.changedFields.includes("oldPassword") && state.oldPassword) {
      if (state.oldPassword.length < 6) {
        hasErrors = true;
        errors.oldPassword = "Minimum 6 characters";
      }
    }

    if (!hasErrors) {
      return true;
    }

    setState({ errors });
  };

  const onSuccess = () => {
    setState({ success: true });
  };

  const handleUpdateUser = () => {
    if (validate()) {
      const body = getChangedFields(state.changedFields, state);

      props.updateUser(body, onSuccess);

      setState({ changedFields: [] });
    }
  };

  const avatar = state.image || state.profileImage;

  let buttonText = props.loading ? "Saving..." : "Save";

  if (state.success) {
    buttonText = "Saved";
  }

  const [photoUploadError, setPhotoUploadError] = useState("");

  return (
    <>
      <div className="user">
        <div
          className="photo"
          style={{ backgroundImage: `url(${avatar ? avatar : user})` }}
        />
        <input
          id="photo-upload"
          type="file"
          accept="image/png, image/jpeg"
          multiple={false}
          hidden
          onChange={handleUpload}
        />
        <div className="buttons">
          <label
            htmlFor="photo-upload"
            className={`
                button
                primary
                button-small
                ${state.loadingImage ? "button-disabled" : ""}
              `}
          >
            {state.loadingImage ? "Uploading Photo..." : "Upload Photo"}
          </label>
          <Button small disabled={!avatar} onClick={deletePhoto}>
            Delete photo
          </Button>
        </div>
      </div>
      {photoUploadError ? (
        <p className="error mb-24">{photoUploadError}</p>
      ) : null}
      <Input
        classNames={{ label: "input-label" }}
        label="First Name"
        placeholder="Your First Name"
        value={state.firstName}
        // @ts-ignore
        onChange={e => updateField("firstName", e.target.value)}
      />
      <Input
        classNames={{ label: "input-label" }}
        label="Last Name"
        placeholder="Your Last Name"
        value={state.lastName}
        // @ts-ignore
        onChange={e => updateField("lastName", e.target.value)}
      />
      <Input
        classNames={{ label: "input-label" }}
        label="Email"
        placeholder="Your Email"
        type="email"
        value={state.email}
        // @ts-ignore
        onChange={e => updateField("email", e.target.value)}
        error={state.errors && state.errors.email}
      />
      <div id="password"></div>
      <PageHeader title="Update Password" />
      <Input
        classNames={{ label: "input-label" }}
        label="Old Password"
        placeholder="Type here"
        value={state.oldPassword}
        // @ts-ignore
        onChange={e => updateField("oldPassword", e.target.value)}
        error={(state.errors && state.errors.oldPassword) || props.error}
        type="password"
        autoComplete="off"
      />
      <Input
        classNames={{ label: "input-label" }}
        label="New Password"
        placeholder="Minimum 6 characters"
        value={state.newPassword}
        // @ts-ignore
        onChange={e => updateField("newPassword", e.target.value)}
        error={state.errors && state.errors.newPassword}
        type="password"
        autoComplete="new-password"
      />
      <PageHeader title="Preferences" />
      <MultileSelect
        classNames={{ label: "input-label" }}
        label="Allergies, intolerances or dislikes"
        placeholder="Select"
        options={props.allergens}
        values={state.allergies}
        onChange={value => updateMultipleField("allergies", value)}
      />
      <div className="button-field">
        <Button
          disabled={
            !state.changedFields.length || props.loading || state.loadingImage
          }
          primary
          limited
          className="save-button"
          onClick={handleUpdateUser}
        >
          {state.success && <img src={check} alt="Saved" />}
          {buttonText}
        </Button>
      </div>
      {/* <p className="error">
        {props.error || (state.errors && "Please, fix errors above")}
      </p> */}
    </>
  );
};

export default withRouter(ProfileTab);
