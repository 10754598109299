import { callGetAdminPlans, callGetLastModifiedPlan } from "api/plans";
import Layout from "components/Layout";
import SavePlanModal from "components/SavePlanModal";
import { PlanList } from "containers/MealPlans/components/PlanList";
import * as React from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useGetCurrentWeek } from "utils/customHooks/use-get-current-week";
import { CreatePlan } from "./components/CreatePlanButton";
import { useEffect, useState } from "react";
import SavePlan from "../../components/SavePlanModal";
import DeletePlanModal from "../../components/DeletePlanModal";
import { useScript } from "utils/useScript";
import AACPModal from "components/AACPModal";

import star from "../../assets/images/common/aacp_star.svg";

export function Plans() {
  const [weeklyPlans, setWeeklyPlans] = React.useState<any[]>();
  const dispatch = useAppDispatch();

  useScript(
    `var _learnq = _learnq || [];var page = window.location.href;_learnq.push(['track', 'Viewed Plans', {url: 'https://rawktheyear.com/plans'}]);`
  );

  const { all: plans, allAACP } = useAppSelector(state => state.plans);
  const isAACPActive = useAppSelector(
    state => state.subscriptions.user.isAACPActive
  );

  const [successModalVisible, setSuccessModalVisible] = useState(false);
  const { start, end } = useGetCurrentWeek();
  React.useEffect(() => {
    const fetchData = async () => {
      const adminPlans = (await callGetAdminPlans()).data.adminPlans;
      setWeeklyPlans(adminPlans);
    };
    fetchData();
    dispatch({ type: "GET_PLANS", start, end });
    dispatch({ type: "GET_AACP_PLANS" });
  }, []);

  const [renameModalVisible, setRenameModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [aacpModalOpen, setAacpModalOpen] = useState(false);
  const [plan, setPlan] = useState(null);
  const [updatePlans, setUpdatePlans] = useState(false);

  useEffect(() => {
    if (!updatePlans) return;
    dispatch({ type: "GET_PLANS", start, end });
    setUpdatePlans(false);
  }, [updatePlans]);

  const openDeleteModal = plan => {
    setDeleteModalVisible(true);
    setPlan(plan);
  };

  const deleteCustomPlan = (id, onSuccess) =>
    dispatch({ type: "DELETE_PLAN", id, onSuccess });
  const deletePlan = () => {
    deleteCustomPlan(plan.nameSlug, () => {
      setUpdatePlans(true);
      closeDeleteModal();
    });
  };

  const closeDeleteModal = () => {
    setDeleteModalVisible(false);
    setPlan(null);
  };

  const openRenameModal = plan => {
    setRenameModalVisible(true);
    setPlan(plan);
  };

  const updateCustomPlan = (id, meals, name, onSuccess) =>
    dispatch({ type: "UPDATE_CUSTOM_PLAN", id, meals, name, onSuccess });
  const renamePlan = async name => {
    const mealPlans = [...plan.mealPlans].map(plan => ({
      ...plan,
      _id: undefined,
      recipe: plan.recipe._id
    }));
    updateCustomPlan(plan.nameSlug, mealPlans, name, () => {
      setUpdatePlans(true);
      closeRenameModal();
    });
  };

  const closeRenameModal = () => {
    setRenameModalVisible(false);
    setPlan(null);
  };

  return (
    <Layout fluid full lessPadding noMorePadding>
      <div className="">
        <PlanList
          bgGray
          title="Weekly Plans"
          plans={weeklyPlans}
          classNames={{ container: "bottom-padding" }}
        />
        <PlanList
          rows={2}
          title="Saved Plans"
          plans={plans}
          header={<CreatePlan />}
          emptyText="You have no saved plans yet..."
          onRenamePlan={openRenameModal}
          onDeletePlan={openDeleteModal}
        />
        {!!allAACP.length && (
          <PlanList
            bgGray
            title="Cleanse Plans"
            plans={allAACP}
            disabled={!isAACPActive}
            onAACPClick={() => setAacpModalOpen(true)}
            isAACP={true}
            titleIcon={<img src={star} className="title-icon" />}
          />
        )}
      </div>
      <SavePlan
        isCustomPlan={plan && plan.name}
        isCreatePlan={false}
        visible={renameModalVisible}
        onSave={renamePlan}
        close={closeRenameModal}
        currentName={plan?.name || ""}
        confirmRequired={true}
        closeSuccess={setSuccessModalVisible}
        visibleSuccess={successModalVisible}
      />
      <DeletePlanModal
        visible={deleteModalVisible}
        onDelete={deletePlan}
        close={closeDeleteModal}
        confirmRequired={true}
      />
      <AACPModal
        visible={aacpModalOpen}
        onClose={() => setAacpModalOpen(false)}
      />
    </Layout>
  );
}
